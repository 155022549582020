<script setup lang="ts">
import { computed, type CSSProperties } from 'vue'

interface Props {
  name: string
  size?: string
  width?: string
  height?: string
  color?: string
}
const defaultSize = '24px'
const props = defineProps<Props>()

const styles = computed<CSSProperties>(() => {
  const data: CSSProperties = {}
  data.width = props.width || props.size || defaultSize
  data.height = props.height || props.size || defaultSize
  if (props.color) {
    data.fill = props.color
    data.color = props.color
  }

  return data
})
</script>

<template>
  <div class="icon">
    <svg
      :class="`icon-${props.name}`"
      :style="styles"
    >
      <use :href="`/images/iconset.svg#icon-${props.name}`"></use>
    </svg>
  </div>
</template>
