import revive_payload_client_4sVQNw7RlN from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/michael/Development/pingzapper/site/pz-site/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/Users/michael/Development/pingzapper/site/pz-site/.nuxt/formkitPlugin.mjs";
import formkit_client_pthRG9e6it from "/Users/michael/Development/pingzapper/site/pz-site/plugins/formkit.client.ts";
import tippy_client_WKoqvTKVZ4 from "/Users/michael/Development/pingzapper/site/pz-site/plugins/tippy.client.ts";
import yup_OM8D6DMeq7 from "/Users/michael/Development/pingzapper/site/pz-site/plugins/yup.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  formkitPlugin_pZqjah0RUG,
  formkit_client_pthRG9e6it,
  tippy_client_WKoqvTKVZ4,
  yup_OM8D6DMeq7
]