<script setup lang="ts">
import { type NuxtError } from '#app'

const props = defineProps<{ error: NuxtError}>()
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="error page content-limit">
    <div class="logo">
      <img src="/images/logo_color.png" width="64px" />
    </div>
    
    <Icon name="large-error" size="96px"/>

    <h1>Oops! An error has occurred</h1>

    <p class="message">
      {{ error.message }}
    </p>

    <button @click="handleError" class="button">Back to home</button>
  </div>
</template>

<style scoped lang="scss">
.error.page {
  margin-top: 2rem;
  text-align: center;

  .logo {
    margin-bottom: 2rem;
  }

  .icon {
    display: inline-flex;
  }

  .button {
    margin-top: 2rem;
  }
}
</style>
