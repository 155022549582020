import { setLocale } from 'yup'

export default defineNuxtPlugin((app) => {
  const t = app.$i18n.t

  const fieldName = (data: any) => {
    return data.spec.meta?.label || data.path
  }

  setLocale({
    mixed: {
      required: (data) => {
        return t('errors.forms.required', { field: fieldName(data) })
      },
    },
    string: {
      email: () => t('errors.forms.email'),
      min: (data) => {
        return t('errors.forms.min', { field: fieldName(data), min: data.min })
      },
      max: (data) => {
        return t('errors.forms.max', { field: fieldName(data), max: data.max })
      },
      length: (data) => {
        return t('errors.forms.length', { field: fieldName(data), length: data.length })
      }
    }
  })
})
