import { default as affiliatesQtyD2ZUW9tMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/affiliates.vue?macro=true";
import { default as serverPCOV5BehmAMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/app/guides/server.vue?macro=true";
import { default as _91id_93AVdXzYhQpZMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/carts/[id].vue?macro=true";
import { default as downloadzBXpkOZ4pGMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/download.vue?macro=true";
import { default as games9a9kZozyErMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/games.vue?macro=true";
import { default as indexFyKizzVSQDMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/index.vue?macro=true";
import { default as upgradeFmeLF2ZZNdMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/legacy/upgrade.vue?macro=true";
import { default as _91key_9340q01TR9z0Meta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/mail/unsubscribe/[id]/[key].vue?macro=true";
import { default as indexBWGBHXLmNUMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/mail/unsubscribe/[id]/index.vue?macro=true";
import { default as capturex8OftGVR97Meta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/capture.vue?macro=true";
import { default as indexLASieyRbo1Meta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/index.vue?macro=true";
import { default as payvJ0eTKdg8SMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/pay.vue?macro=true";
import { default as statusyMmTNCTsZYMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/status.vue?macro=true";
import { default as ordersNslM9AQQeeMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/orders.vue?macro=true";
import { default as plansI9jAFm2nvAMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/plans.vue?macro=true";
import { default as privacyP2FfheWtzoMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/privacy.vue?macro=true";
import { default as redirect0AqXclRy9dMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/redirect.vue?macro=true";
import { default as _91username_933qUkI213boMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/ref/[username].vue?macro=true";
import { default as indexQoyDdRlILXMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/resellers/index.vue?macro=true";
import { default as voucherswtzjHmZlzkMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/resellers/vouchers.vue?macro=true";
import { default as serverssQZaJW3ZZ7Meta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/servers.vue?macro=true";
import { default as sign_inKushNfygvGMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/sign_in.vue?macro=true";
import { default as sign_upyWVFmjNJMqMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/sign_up.vue?macro=true";
import { default as indexlmXJ8ZHPJyMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/billing/index.vue?macro=true";
import { default as setupXgeKFAAG68Meta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/billing/setup.vue?macro=true";
import { default as changezviaKYturhMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/change.vue?macro=true";
import { default as helloRLEzo2JrnIMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/hello.vue?macro=true";
import { default as indexOYjPqenYcRMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/index.vue?macro=true";
import { default as newxRKpsSANlhMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/new.vue?macro=true";
import { default as redeem3KbcNRe3BTMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/redeem.vue?macro=true";
import { default as renewf96IPWXiWrMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/renew.vue?macro=true";
import { default as trial8vBVQ7mGSnMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/trial.vue?macro=true";
import { default as welcome_backO298mzKOwnMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/welcome_back.vue?macro=true";
import { default as subscriptiontvX14eM9MfMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/subscription.vue?macro=true";
import { default as supportO1YN8yH4OxMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/support.vue?macro=true";
import { default as termswliZILK1DGMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/terms.vue?macro=true";
import { default as _91token_93olagLpFqwQMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/confirm/[token].vue?macro=true";
import { default as email4iTZQz0W8zMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/edit/email.vue?macro=true";
import { default as index1F2eWHNBijMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/edit/index.vue?macro=true";
import { default as indexysDUBtuSdYMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/index.vue?macro=true";
import { default as _91token_93gR7ny7zKVQMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/reset/[token].vue?macro=true";
import { default as indexekT5LrYzhMMeta } from "/Users/michael/Development/pingzapper/site/pz-site/pages/users/reset/index.vue?macro=true";
export default [
  {
    name: "affiliates",
    path: "/affiliates",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: "app-guides-server",
    path: "/app/guides/server",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/app/guides/server.vue").then(m => m.default || m)
  },
  {
    name: "carts-id",
    path: "/carts/:id()",
    meta: _91id_93AVdXzYhQpZMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/carts/[id].vue").then(m => m.default || m)
  },
  {
    name: "download",
    path: "/download",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/download.vue").then(m => m.default || m)
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/games.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legacy-upgrade",
    path: "/legacy/upgrade",
    meta: upgradeFmeLF2ZZNdMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/legacy/upgrade.vue").then(m => m.default || m)
  },
  {
    name: "mail-unsubscribe-id-key",
    path: "/mail/unsubscribe/:id()/:key()",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/mail/unsubscribe/[id]/[key].vue").then(m => m.default || m)
  },
  {
    name: "mail-unsubscribe-id",
    path: "/mail/unsubscribe/:id()",
    meta: indexBWGBHXLmNUMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/mail/unsubscribe/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: ordersNslM9AQQeeMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-capture",
    path: ":id()/capture",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/capture.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: ":id()",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-pay",
    path: ":id()/pay",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-status",
    path: ":id()/status",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/orders/[id]/status.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "plans",
    path: "/plans",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "ref-username",
    path: "/ref/:username()",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/ref/[username].vue").then(m => m.default || m)
  },
  {
    name: "resellers",
    path: "/resellers",
    meta: indexQoyDdRlILXMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/resellers/index.vue").then(m => m.default || m)
  },
  {
    name: "resellers-vouchers",
    path: "/resellers/vouchers",
    meta: voucherswtzjHmZlzkMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/resellers/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "servers",
    path: "/servers",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/servers.vue").then(m => m.default || m)
  },
  {
    name: "sign_in",
    path: "/sign_in",
    meta: sign_inKushNfygvGMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/sign_in.vue").then(m => m.default || m)
  },
  {
    name: "sign_up",
    path: "/sign_up",
    meta: sign_upyWVFmjNJMqMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/sign_up.vue").then(m => m.default || m)
  },
  {
    name: subscriptiontvX14eM9MfMeta?.name,
    path: "/subscription",
    meta: subscriptiontvX14eM9MfMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription.vue").then(m => m.default || m),
    children: [
  {
    name: "subscription-billing",
    path: "billing",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-billing-setup",
    path: "billing/setup",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/billing/setup.vue").then(m => m.default || m)
  },
  {
    name: "subscription-change",
    path: "change",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/change.vue").then(m => m.default || m)
  },
  {
    name: "subscription-hello",
    path: "hello",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/hello.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-new",
    path: "new",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/new.vue").then(m => m.default || m)
  },
  {
    name: "subscription-redeem",
    path: "redeem",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/redeem.vue").then(m => m.default || m)
  },
  {
    name: "subscription-renew",
    path: "renew",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/renew.vue").then(m => m.default || m)
  },
  {
    name: "subscription-trial",
    path: "trial",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/trial.vue").then(m => m.default || m)
  },
  {
    name: "subscription-welcome_back",
    path: "welcome_back",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/subscription/welcome_back.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "users-confirm-token",
    path: "/users/confirm/:token()",
    meta: _91token_93olagLpFqwQMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/confirm/[token].vue").then(m => m.default || m)
  },
  {
    name: "users-edit-email",
    path: "/users/edit/email",
    meta: email4iTZQz0W8zMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "users-edit",
    path: "/users/edit",
    meta: index1F2eWHNBijMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexysDUBtuSdYMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "users-reset-token",
    path: "/users/reset/:token()",
    meta: _91token_93gR7ny7zKVQMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "users-reset",
    path: "/users/reset",
    meta: indexekT5LrYzhMMeta || {},
    component: () => import("/Users/michael/Development/pingzapper/site/pz-site/pages/users/reset/index.vue").then(m => m.default || m)
  }
]