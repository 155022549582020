import validate from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/pages/runtime/validate.js";
import clear_45flash_45global from "/Users/michael/Development/pingzapper/site/pz-site/middleware/clearFlash.global.ts";
import region_45redirect_45global from "/Users/michael/Development/pingzapper/site/pz-site/middleware/regionRedirect.global.ts";
import manifest_45route_45rule from "/Users/michael/Development/pingzapper/site/pz-site/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clear_45flash_45global,
  region_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-auth": () => import("/Users/michael/Development/pingzapper/site/pz-site/middleware/checkAuth.ts"),
  "check-no-auth": () => import("/Users/michael/Development/pingzapper/site/pz-site/middleware/checkNoAuth.ts")
}