<script setup lang="ts">
useHead({
  title: 'PingZapper'
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
