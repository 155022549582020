export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.server) return

  const nuxtApp = useNuxtApp()
  const flash = useFlash()
  const userState = useUserState()
  const { region, redirectToRegion } = useRegion()

  // Check if already been redirected
  if (to.query.region_redirect) {
    const { t } = nuxtApp.$i18n
    flash.set(
      t(
        'home.redirected_to_region',
        { region: t(`regions.${region}`) }
      ),
      { kind: 'success' }
    )
    return to.path
  } else {
    if (region === 'global' && userState.value.country === 'CN') {
      return redirectToRegion({ region: 'china', path: ''})
    }
  }
})
