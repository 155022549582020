interface FlashMessage {
  immediate: boolean,
  message: string,
  kind: string
}

interface FlashSetOpts {
  kind?: string,
  immediate?: boolean
}

const useFlash = () => {
  const cookie = useCookie<FlashMessage | undefined>('flash', {
    sameSite: 'strict',
    watch: true
  })

  return {
    clear() {
      this.cookie.value = undefined
    },
    set(
      message: string,
      options: FlashSetOpts = { immediate: false, kind: 'info' }
    ) {
      this.cookie.value = {
        immediate: options.immediate || false,
        kind: options.kind || 'info',
        message
      }
    },
    cookie,
  }
}

export { useFlash }
export type { FlashMessage }
